import dayjs from 'dayjs';
import { capitalizeWords } from 'rapidfab/utils/stringUtils';

export const addTimeZoneToDateString = value => {
  if (!value) {
    return null;
  }

  // Convert dates to ISO string in order to include timezone
  const date = dayjs(value);
  return date.toISOString();
};

export const removeTimeZoneFromDateString = value => {
  if (!value) {
    return '';
  }

  // Convert to user timezone and change to genetic date format (YYYY-MM-DD)
  const fieldDate = dayjs(value);
  return fieldDate.format('YYYY-MM-DD');
};

/* This method will extract the Resource Values to the Initial Form Values,
   the same way as we did previously:

   const initialFormValues = {};

   Object
    .keys(<RESOURCE>)

    .filter(key => <FORM_DATA_VALUES.FIELDS>.includes(key))
    .forEach(key => {
      initialFormValues[key] = <RESOURCE>[key];
    });

   *** -> FORM_DATA_VALUES.FIELDS - locates in rapidfab/constants/forms.js

   */
export const extractInitialFormValues = (source, keys) => {
  const result = {};
  keys.forEach(key => {
    if (source[key] !== undefined) {
      result[key] = source[key];
    }
  });
  return result;
};

/* This method will create the sub-dict names "subObjectKey" and
   move the keys/values described in "keys" from the root object to
   the sub-dict names "subObjectKey". It will skip the keys from "keys"
   and will not move them to "subObjectKey" + delete from the initial object.
*/
export const replaceFormValuesIntoSubDict = (source, keys, subObjectKey, keysToSkip = []) => {
  const result = {
    [subObjectKey]: {},
  };

  Object.entries(source).forEach(([key, value]) => {
    if (keys.includes(key) && !keysToSkip.includes(key)) {
      result[subObjectKey][key] = value;
    } else if (!keys.includes(key)) {
      result[key] = value;
    }
  });

  return result;
};

/**
 * @description Sorts an array of objects based on the specified key in a case-insensitive manner.
 *
 * @param {Array} data - The array of objects to be sorted.
 * @param {string} key - The key of the objects on which the sorting will be applied.
 *
 * @example
 * const dataArray = [
 *   { name: "Alice" },
 *   { name: "charlie" },
 *   { name: "bob" },
 * ];
 * const sortedArray = compareIgnoreCase(dataArray, "name");
 * console.info(sortedArray);
 * // Output: [{ name: "Alice" }, { name: "bob" }, { name: "charlie" }]
 *
 * @returns {Array} The sorted array of objects.
 */

export const compareIgnoreCase = (data, key) =>
  data.sort((a, b) =>
    (a[key] || '').trim().localeCompare((b[key] || '').trim()),
  );

const isValidPhoneNumber = (phone, pattern = /^[\d()\\-]+$/) => (pattern.test(phone) ? '' : 'Invalid phone number format.');
export const validateRequiredField = (value, field) =>
  ((value === null || value === undefined) ? `${capitalizeWords(field) || 'This field'} is required.` : undefined);

export const validateNestedField = (field, fieldName, additionalData) => {
  const { pattern, label } = additionalData ?? {};
  const errors = {};
  const requiredError = validateRequiredField(field, label);

  if (requiredError) {
    errors[fieldName] = requiredError;
  } else if (fieldName === 'phone') {
    const phoneError = isValidPhoneNumber(field, pattern);
    if (phoneError) {
      errors[fieldName] = phoneError;
    }
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const findGroupedSectionByField = (field, groupedSections, defaultSection) => {
  // Split the field name to handle nested fields such as "build_volume.x"
  const topLevelFieldName = field.split('.')[0];
  const section = Object.entries(groupedSections)
    .find(([, fields]) => fields.includes(topLevelFieldName));
  return section ? section[0] : defaultSection; // Returns the section key if found, otherwise 'general'
};

export const handleGetMissingRequiredFieldsForSection = (
  errors,
  groupedSections,
  requiredFieldsTitles,
  defaultSection,
) => {
  const sectionsToExpand = {};
  const newSectionsWithMissingFields = {};

  Object.keys(errors).forEach(field => {
    const section = findGroupedSectionByField(field, groupedSections, defaultSection);
    sectionsToExpand[section] = true; // Mark this section to be expanded

    // Initialize the section in the object if it doesn't already exist
    if (!newSectionsWithMissingFields[section]) {
      newSectionsWithMissingFields[section] = [];
    }

    // Add the field to the section if there's an error, use the title instead of the key
    if (errors[field]) {
      const fieldTitle = requiredFieldsTitles[field] || field; // fallback to key if title not found
      newSectionsWithMissingFields[section].push(fieldTitle);
    }
  });

  return {
    missingRequiredFieldsBySections: newSectionsWithMissingFields,
    sectionsToExpand,
  };
};

// React Select helper for creating the Option from the value.
export const createSelectOption = ({ label, value }) => {
  if (!value) return null;
  return ({ label: label ?? value, value });
};

/**
 * Checks if current fusion printer settings differ from original association
 *
 * @param {Object} association - Original association from API
 * @param {Object} vendor - Current vendor selection (react-select object)
 * @param {Object} machineModel - Current machine model selection (react-select object)
 * @param {Object} name - Current name selection (react-select object)
 * @returns {boolean} True if settings have changed, false otherwise
 */
export const haveFusionSettingsChanged = (
  association,
  vendor,
  machineModel,
  name,
) => {
  // Check if all current selections exist
  const hasAllSelections = vendor?.value && machineModel?.value && name?.value;

  // New association case (no original but has selections)
  if (!association && hasAllSelections) return true;

  // Check for differences between original and current selections
  return association && hasAllSelections ? (
    association.vendor !== vendor.value ||
    association.machine_model !== machineModel.value ||
    association.name !== name.value
  ) : false;
};
