import { useSelector } from 'react-redux';
import {
  isSessionManager,
  isCurrentUserRestricted,
  isLocationManagerRole,
  getCurrentLocationManagerLocations,
  getCurrentUserLocations,
} from 'rapidfab/selectors';

const useDisabledFor = ({ nonManager, alwaysEnabled, restrictedUser, location }) => {
  const isCurrentRestrictedUser = useSelector(isCurrentUserRestricted);
  const isCurrentLocationManager = useSelector(isLocationManagerRole);
  const isBureauManager = useSelector(isSessionManager);
  const getLocationsForUser = useSelector(getCurrentUserLocations);
  const getLocationsForLocationManager = useSelector(getCurrentLocationManagerLocations);

  const isRestrictedUser = isCurrentRestrictedUser;
  const isLocationUser = location && !isBureauManager && !isCurrentLocationManager;
  const isLocationManager = location && isCurrentLocationManager && !isBureauManager;

  const hasAccess = () => {
    if (alwaysEnabled) return true;

    if (location) {
      if (isLocationUser && !getLocationsForUser.some(user => user.location === location)) {
        return false;
      }

      if (isLocationManager && !getLocationsForLocationManager.some(user => user.location === location)) {
        return false;
      }
    }

    if (restrictedUser && isRestrictedUser) {
      return false;
    }

    if (nonManager && !isBureauManager && !isCurrentLocationManager) {
      return false;
    }

    return true;
  };

  if (!(nonManager || restrictedUser)) {
    throw new Error('You must provide at least one conditional prop to useDisabledFor.');
  }

  if ((nonManager && restrictedUser)) {
    throw new Error('You provided mutually exclusive props to useDisabledFor.');
  }

  return { isDisabled: !hasAccess() };
};

export default useDisabledFor;
