import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  isSessionManager,
  isCurrentUserRestricted,
  isLocationManagerRole,
  getCurrentLocationManagerLocations,
} from 'rapidfab/selectors';
/**
 * Passes `disabled` prop when current user has no proper permission/role
 * into children render function for outer component to use it for their needs.
 * E.g. <DisabledFor manager>{({ disabled }) => <Button disabled={disabled}>Submit Button</Button}</DisabledFor>
 *
 * Current component was partially inspired by VisibleFor
 * so has partially copy-pasted code from there
 *
 * @param isCurrentUserManager {Bool}
 * @param manager {Bool}
 * @param nonManager {Bool}
 * @param alwaysEnabled {Bool} Set this to `true` to ignore all other checks and just render children as is
 * @param disabledPrefix {JSX.Element} Disabled prefix node.
 * Pass your custom component to show when access is prohibited
 * @param children {function} a function which accepts object with `disabled` prop
 * to allow customizing childrent elements based on the value
 * @returns {JSX.Element}
 * @constructor
 */
const DisabledFor = ({
  isCurrentUserManager,
  manager,
  nonManager,
  alwaysEnabled,
  children,
  disabledPrefix,
  isCurrentUserRestricted,
  restrictedUser,
  isCurrentUserLocationManager,
  location,
  getLocationsCurrentLocationManager,
}) => {
  if (typeof children !== 'function') {
    throw new TypeError('You have to provide children as a function into DisabledFor component.');
  }

  const hasAccess = () => {
    if (alwaysEnabled) {
      return true;
    }

    if (manager || nonManager) {
      if (manager && isCurrentUserManager) {
        return false;
      }

      if (nonManager && !isCurrentUserManager && !isCurrentUserLocationManager) {
        return false;
      }

      if (location && isCurrentUserLocationManager && !isCurrentUserManager) {
        const isLocationMatch = getLocationsCurrentLocationManager.some(
          user => user.location === location,
        );

        if (!isLocationMatch) {
          return false;
        }
      }
    }

    if (restrictedUser && isCurrentUserRestricted) {
      return false;
    }

    return true;
  };

  const mutuallyExclusiveProps = [
    [manager, nonManager, restrictedUser],
  ];

  if (!mutuallyExclusiveProps.flat().filter(Boolean).length) {
    throw new Error('You have to provide at least one conditional prop for DisabledFor component');
  }

  // Filter out pair with only true values
  if (mutuallyExclusiveProps.filter(a => a[0] && a[1]).length > 0) {
    throw new Error('You provided at least 1 pair of mutually exclusive props in DisabledFor component');
  }

  const isDisabled = !hasAccess();

  return (
    <>
      {isDisabled && disabledPrefix}
      {children({ disabled: isDisabled })}
    </>
  );
};

DisabledFor.defaultProps = {
  manager: false,
  nonManager: false,
  alwaysEnabled: false,
  disabledPrefix: null,
  restrictedUser: false,
  location: null,
};

DisabledFor.propTypes = {
  children: PropTypes.func.isRequired,
  isCurrentUserManager: PropTypes.bool.isRequired,
  isCurrentUserRestricted: PropTypes.bool.isRequired,
  isCurrentUserLocationManager: PropTypes.bool.isRequired,
  getLocationsCurrentLocationManager: PropTypes.func.isRequired,
  manager: PropTypes.bool,
  nonManager: PropTypes.bool,
  alwaysEnabled: PropTypes.bool,
  disabledPrefix: PropTypes.node,
  restrictedUser: PropTypes.bool,
  location: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isCurrentUserManager: isSessionManager(state),
    isCurrentUserRestricted: isCurrentUserRestricted(state),
    isCurrentUserLocationManager: isLocationManagerRole(state),
    getLocationsCurrentLocationManager: getCurrentLocationManagerLocations(state),
  };
}

export default connect(mapStateToProps)(DisabledFor);
