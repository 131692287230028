import React from 'react';
import PropTypes from 'prop-types';

const FusionLogo = ({ width = 27, height = 25 }) => (
  <svg width={width} height={height} viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="fusion-logo">
    <path d="M24.0577 0C24.1514 0.070411 24.1964 0.161566 24.2692 0.207862C24.7353 0.504489 25.0239 0.890819 25.0154 1.48332C25.0008 2.4917 25.0115 3.50047 24.9879 4.56294C24.9781 4.84442 25.0041 5.07202 25.0042 5.29964C25.0066 9.77797 25.0097 14.2563 25.0015 18.7346C25.0004 19.311 24.9373 19.8872 24.905 20.4299C18.0509 20.4299 11.2094 20.4299 4.3656 20.3968C4.38666 20.1216 4.42934 19.8796 4.43026 19.6374C4.43632 18.0406 4.43924 16.4437 4.4323 14.8469C4.41077 9.89791 4.38437 4.94896 4.35986 0C10.9138 0 17.4678 0 24.0577 0ZM11.6903 15.9134C12.6034 15.9134 13.5166 15.9134 14.474 15.9134C14.474 14.3744 14.474 12.8702 14.474 11.3381C15.7115 11.3381 16.9045 11.3381 18.1008 11.3381C18.1008 10.6577 18.1008 10.0066 18.1008 9.32772C16.881 9.32772 15.6877 9.32772 14.4809 9.32772C14.4809 8.42821 14.4809 7.55721 14.4809 6.67829C14.5678 6.67352 14.6303 6.66712 14.6929 6.66709C15.9421 6.66644 17.1914 6.6588 18.4405 6.67227C18.7285 6.67538 18.8224 6.58001 18.8086 6.29274C18.7887 5.87954 18.7827 5.46321 18.8099 5.05087C18.833 4.70104 18.7354 4.58268 18.3684 4.58585C16.2526 4.60412 14.1365 4.59475 12.0205 4.59475C11.8963 4.59475 11.7721 4.59475 11.6902 4.59475C11.6902 4.99254 11.6902 5.34843 11.6902 5.70432C11.6902 7.39988 11.6973 9.09548 11.6882 10.791C11.6792 12.4827 11.6541 14.1743 11.6903 15.9134Z" fill="#FF6B00" />
    <path d="M4.36795 20.4301C11.2094 20.4301 18.0509 20.4301 24.905 20.4301C24.9374 19.8873 25.0005 19.3111 25.0015 18.7348C25.0098 14.2565 25.0067 9.77816 25.0043 5.29982C25.0042 5.0722 24.9781 4.8446 24.9887 4.59949C25.6598 4.58199 26.3063 4.58199 27.0001 4.58199C27.0001 11.3918 27.0001 18.1634 27.0001 24.9715C19.1203 25.0079 11.2405 25.0079 3.33171 24.9803C2.58596 24.5286 2.28771 23.9023 2.36416 23.0609C2.40366 22.6262 2.36962 22.1846 2.37901 21.7212C3.04879 21.2742 3.70837 20.8522 4.36795 20.4301Z" fill="#933C00" />
    <path d="M4.36549 20.3968C3.70822 20.852 3.04864 21.2741 2.35321 21.7288C2.07677 21.9122 1.83913 22.0681 1.59496 22.2125C1.06508 22.526 0.531859 22.8337 3.69468e-09 23.1438C-4.55227e-07 16.3336 -4.51339e-07 9.5235 0.027978 2.67657C1.20321 1.94214 2.35344 1.24945 3.4954 0.542994C3.74754 0.387014 3.97043 0.182283 4.20678 7.40917e-08C4.24078 -3.57195e-09 4.27477 0 4.33426 0C4.38426 4.94896 4.41066 9.89791 4.43219 14.8469C4.43914 16.4437 4.43621 18.0406 4.43016 19.6374C4.42924 19.8796 4.38655 20.1216 4.36549 20.3968Z" fill="#FF9548" />
    <path d="M11.6631 15.8902C11.654 14.1748 11.6791 12.4832 11.6882 10.7915C11.6972 9.09596 11.6901 7.40036 11.6901 5.7048C11.6901 5.34891 11.6901 4.99302 11.6901 4.59524C11.772 4.59524 11.8962 4.59524 12.0204 4.59524C14.1364 4.59524 16.2525 4.6046 18.3684 4.58633C18.7354 4.58316 18.8329 4.70152 18.8098 5.05135C18.7826 5.46369 18.7886 5.88002 18.8085 6.29322C18.8223 6.58049 18.7284 6.67586 18.4404 6.67275C17.1913 6.65928 15.942 6.66692 14.6928 6.66757C14.6302 6.6676 14.5677 6.674 14.4808 6.67877C14.4808 7.55769 14.4808 8.42869 14.4808 9.32821C15.6876 9.32821 16.8809 9.32821 18.1007 9.32821C18.1007 10.0071 18.1007 10.6582 18.1007 11.3386C16.9044 11.3386 15.7115 11.3386 14.474 11.3386C14.474 12.8706 14.474 14.3749 14.474 15.9139C13.5165 15.9139 12.6034 15.9139 11.6631 15.8902Z" fill="white" />
  </svg>
);

FusionLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

FusionLogo.defaultProps = {
  width: 27,
  height: 25,
};

export default FusionLogo;
