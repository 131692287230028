import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import {
  Card,
  Col,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { selectInputStyles } from 'rapidfab/constants/styles';
import { FormattedMessage } from 'react-intl';
import Loading from 'rapidfab/components/Loading';

const FusionPrinterTypeSettings = ({
  fusionSettings,
  fusionSettingsStates,
  fusionSettingsFetching,
  lazyLoadFusionPrinterTypeSettings,
  fusionAssociationSettingsFetching,
}) => {
  const {
    selectedVendor,
    setSelectedVendor,
    selectedMachineModel,
    setSelectedMachineModel,
    selectedName,
    setSelectedName,
  } = fusionSettingsStates;

  const [vendorMenuOpen, setVendorMenuOpen] = useState(false);

  const vendorOptions = useMemo(() => {
    const uniqueVendors = [...new Set(fusionSettings.map(setting =>
      setting.vendor))].filter(Boolean);
    return uniqueVendors.map(vendor => ({ label: vendor, value: vendor }));
  }, [fusionSettings]);

  const machineModelOptions = useMemo(() => {
    if (!selectedVendor) return [];

    const filteredSettings = fusionSettings.filter(setting =>
      setting.vendor === selectedVendor.value);

    const uniqueModels = [...new Set(filteredSettings.map(setting => setting.machine_model))].filter(Boolean);
    return uniqueModels.map(model => ({ label: model, value: model }));
  }, [fusionSettings, selectedVendor]);

  const nameOptions = useMemo(() => {
    if (!selectedVendor || !selectedMachineModel) return [];

    const filteredSettings = fusionSettings.filter(setting =>
      setting.vendor === selectedVendor.value &&
      setting.machine_model === selectedMachineModel.value);

    const uniqueNames = [...new Set(filteredSettings.map(setting => setting.name))].filter(Boolean);
    return uniqueNames.map(name => ({ label: name, value: name }));
  }, [fusionSettings, selectedVendor, selectedMachineModel]);

  const handleFusionVendorChange = option => {
    setSelectedVendor(option);
    setSelectedMachineModel(null);
    setSelectedName(null);
    setVendorMenuOpen(false);
  };

  const handleFusionMachineModelChange = option => {
    setSelectedMachineModel(option);
    setSelectedName(null);
  };

  const handleFusionNameChange = option => {
    setSelectedName(option);
  };

  // Fetch data and open menu when complete
  const handleVendorMenuOpen = async () => {
    // Close initially to show loading
    setVendorMenuOpen(false);

    await lazyLoadFusionPrinterTypeSettings();

    // Open menu when complete
    setVendorMenuOpen(true);
  };

  const handleVendorMenuClose = () => setVendorMenuOpen(false);

  return (
    <Card className="custom-darken-modal--card mb15 fusion-card">
      <Card.Header className="pd-exp custom-darken-modal--card-header">
        <Row className="d-flex justify-content-between align-items-center">
          <Col lg={6}>
            <FormattedMessage id="field.autodeskFusionSettings" defaultMessage="Autodesk Fusion Settings" />
          </Col>
        </Row>
      </Card.Header>
      {fusionAssociationSettingsFetching ? <Loading className="p-10" /> : (
        <Card.Body className="custom-darken-modal--card-body">
          <Row>
            <Col lg={5} xs={12}>
              <FormGroup className="form-group" controlId="uxFusionVendor">
                <FormLabel>
                  <FormattedMessage id="field.vendor" defaultMessage="Vendor" />
                  :
                </FormLabel>

                <Select
                  options={vendorOptions}
                  onChange={handleFusionVendorChange}
                  onMenuOpen={handleVendorMenuOpen}
                  onMenuClose={handleVendorMenuClose}
                  isLoading={fusionSettingsFetching}
                  isDisabled={fusionSettingsFetching}
                  menuIsOpen={fusionSettingsFetching ? false : vendorMenuOpen}
                  components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                  placeholder={<FormattedMessage id="field.vendor.select" defaultMessage="Select Vendor" />}
                  value={selectedVendor}
                  styles={selectInputStyles}
                  isClearable
                />
              </FormGroup>
            </Col>
            <Col lg={7} xs={12}>
              <FormGroup className="form-group" controlId="uxFusionMachineModel">
                <FormLabel>
                  <FormattedMessage id="field.machineModel" defaultMessage="Machine Model" />
                  :
                </FormLabel>

                <Select
                  options={machineModelOptions}
                  onChange={handleFusionMachineModelChange}
                  placeholder={<FormattedMessage id="field.machineModel.select" defaultMessage="Select Machine Model" />}
                  value={selectedMachineModel}
                  styles={selectInputStyles}
                  isClearable
                  isDisabled={!selectedVendor}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={12}>
              <FormGroup className="form-group" controlId="uxFusionName">
                <FormLabel>
                  <FormattedMessage id="field.name" defaultMessage="Name" />
                  :
                </FormLabel>

                <Select
                  options={nameOptions}
                  onChange={handleFusionNameChange}
                  placeholder={<FormattedMessage id="field.name.select" defaultMessage="Select Name" />}
                  value={selectedName}
                  styles={selectInputStyles}
                  isClearable
                  isDisabled={!selectedMachineModel}
                />
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

FusionPrinterTypeSettings.propTypes = {
  fusionSettings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fusionSettingsStates: PropTypes.shape({
    selectedVendor: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    selectedMachineModel: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    selectedName: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    setSelectedVendor: PropTypes.func.isRequired,
    setSelectedMachineModel: PropTypes.func.isRequired,
    setSelectedName: PropTypes.func.isRequired,
  }).isRequired,
  fusionSettingsFetching: PropTypes.bool.isRequired,
  fusionAssociationSettingsFetching: PropTypes.bool.isRequired,
  lazyLoadFusionPrinterTypeSettings: PropTypes.func.isRequired,
};

export default FusionPrinterTypeSettings;
