import {
  faCheckCircle,
  faChevronRight,
  faExclamationCircle,
  faInfoCircle,
  faTriangleExclamation,
  faArrowAltCircleUp,
  faHistory,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import FusionButtonLink from 'rapidfab/components/autodesk-fusion/FusionButtonLink';
import Loading from 'rapidfab/components/Loading';
import { ActionTooltip } from 'rapidfab/components/Tooltip';
import Constants, {
  API_RESOURCES,
  AUTODESK_FUSION_MODEL_STATUSES, EVENT_STREAM_SELECTOR_RESOURCES, MODEL_STATUSES,
} from 'rapidfab/constants';
import {
  getFusionModels,
  getFusionModelsVersions,
  getStateEventStreamEvents,
} from 'rapidfab/selectors';
import { getStateFusionUserToken } from 'rapidfab/selectors/baseStateSelectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FusionVersionsModal from 'rapidfab/components/autodesk-fusion/FusionVersionsModal';
import { FormattedMessage } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';
import Alert from 'rapidfab/utils/alert';

const AutodeskFusionPanel = ({ model, newModelLibraryCreation }) => {
  const fusionModel = useSelector(state => getFusionModels(state)[0]);
  const fusionModelVersions = useSelector(state =>
    getFusionModelsVersions(state),
  );
  const fusionToken = useSelector(state => getStateFusionUserToken(state)[0]);
  const fusionModelFetching = useSelector(
    state => state.ui.nautilus[API_RESOURCES.FUSION_MODEL].list.fetching,
  );
  const fusionModelVersionUpdating = useSelector(
    state => state.ui.nautilus[API_RESOURCES.FUSION_MODEL].put.fetching,
  );
  const fusionModelVersionsFetching = useSelector(
    state =>
      state.ui.nautilus[API_RESOURCES.FUSION_MODEL_VERSIONS].get.fetching,
  );

  // Get eventStream events related to Fusion Model updates
  const fusionModelEvents = useSelector(state =>
    getStateEventStreamEvents(state, EVENT_STREAM_SELECTOR_RESOURCES.FUSION_MODEL));

  const fusionUri = fusionModel?.fusion_uri;
  const fusionVersion = fusionModel?.version;

  const [showVersionsModal, setShowVersionsModal] = useState(false);
  const [fusionVersionError, setFusionVersionError] = useState(null);
  const [fusionModelVersionsFetchedByPing, setFusionModelVersionsFetchedByPing] = useState(false);
  const [fusionModelFetchedByEvent, setFusionModelFetchedByEvent] = useState(false);

  const dispatch = useDispatch();

  const fetchFusionModelVersions = fusionModelVersionUri => {
    if (!fusionModelVersionUri) return;
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.FUSION_MODEL_VERSIONS].get(
        extractUuid(fusionModelVersionUri),
      ),
    ).catch(error => {
      console.error('Failed to fetch fusion model versions:', error);
      setFusionVersionError(error);
    });
  };

  const fetchFusionModel = modelUri => {
    if (!modelUri) return Promise.reject(new Error('No model URI provided'));
    // No need to fetch Fusion Model if the model itself is not uploaded.
    if (!model || model?.status === MODEL_STATUSES.NOT_UPLOADED) return Promise.resolve({});
    dispatch(Actions.Api.nautilus[API_RESOURCES.FUSION_MODEL].clear('list'));
    return dispatch(Actions.Api.nautilus[API_RESOURCES.FUSION_MODEL].list({ model: modelUri }));
  };

  // Track the eventStream updates to update data based on the specific event
  const trackFusionEventStreamEvents = modelUri => {
    if (_isEmpty(fusionModelEvents) || !modelUri || !fusionToken) return;

    const { payload } = fusionModelEvents ?? {};

    const fusionModelStatus = payload?.status;
    const statusesToPingFusionModelUpdate = [
      AUTODESK_FUSION_MODEL_STATUSES.new,
      AUTODESK_FUSION_MODEL_STATUSES.processing,
      AUTODESK_FUSION_MODEL_STATUSES.ready,
    ];

    const isFusionModelAvailable = !fusionModelFetching && !_isEmpty(fusionModel);

    /* Fetch fusion model if:
    * Corresponding status is available
    * Fusion Model is not fetched initially
    * Fusion Model was not already fetched by this event tracking method
    */
    if (
      statusesToPingFusionModelUpdate.includes(fusionModelStatus)
      && !isFusionModelAvailable
      && !fusionModelFetchedByEvent
    ) {
      fetchFusionModel(modelUri);
      // Set the flag to skip re-fetching
      setFusionModelFetchedByEvent(true);
    }

    const fusionModelReadyAndAvailable = fusionModel?.uri &&
      fusionModel?.status === AUTODESK_FUSION_MODEL_STATUSES.ready;

    /* Fetch Fusion Model Versions if:
    * Fusion Model is available and has corresponding status
    * We did not fetch the versions already by this event tracking method
    * Fusion Versions are not defined
    */
    if (fusionModelReadyAndAvailable
      && !fusionModelVersionsFetchedByPing
      && _isEmpty(fusionModelVersions)
    ) {
      fetchFusionModelVersions(fusionModel.uri);
      setFusionModelVersionsFetchedByPing(true);
    }
  };

  const onInitialize = modelUri => {
    if (!modelUri) return;

    // On init -> clear the fusion old related cached data.
    dispatch(Actions.Api.nautilus[API_RESOURCES.FUSION_MODEL_VERSIONS].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.FUSION_MODEL].clear('list'));

    // Skip fetching Fusion Model if we are now creating the Model Library
    // As we rely on eventStream and trigger fetch when we have the required event
    if (!newModelLibraryCreation) {
      fetchFusionModel(modelUri)
        .then(response => {
          const fusionModel = response.json?.resources?.[0];
          // Only fetch versions if the model is ready
          if (fusionModel?.status === AUTODESK_FUSION_MODEL_STATUSES.ready) {
            fetchFusionModelVersions(fusionModel.uri);
          }
        }).catch(error => {
          console.error('Failed to fetch fusion model:', error);
          Alert.error(error.message || 'Failed to fetch fusion model');
        });
    }
  };

  useEffect(() => onInitialize(model?.uri), [model?.uri]);

  if (!model) return null;

  const handleShowVersionsModal = () => setShowVersionsModal(true);
  const handleCloseVersionsModal = () => setShowVersionsModal(false);

  const handleSetFusionVersion = newVersion => {
    if (!newVersion || !fusionModel) return;
    dispatch(Actions.Api.nautilus[API_RESOURCES.FUSION_MODEL].put(
      extractUuid(fusionModel.uri),
      { version: newVersion },
    )).then(response => {
      // If the version was updated successfully -> re-fetch Fusion Model
      if (response?.type === Constants.RESOURCE_PUT_SUCCESS) {
        Alert.success('The version has been successfully updated');
        fetchFusionModel(model.uri);
      }
    });
  };

  useEffect(() => {
    trackFusionEventStreamEvents(model?.uri);
  }, [fusionModelEvents, model?.uri]);

  // Common processing content configuration
  const processingContent = {
    content: <span>Processing</span>,
    disabled: true,
    hidden: true,
  };

  const renderVersionStatusContent = () => {
    const latestVersionAvailable = Math.max(
      ...fusionModelVersions.map(fusionVersionItem => fusionVersionItem.version),
    );
    const isCurrentVersionLatest = fusionVersion === latestVersionAvailable || latestVersionAvailable < 0;

    if (fusionVersionError) {
      return {
        icon: (
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="spacer-right"
          />
        ),
        content: (
          <div>
            <div className="fusion-main-title">Ready, version not synced</div>
            <div className="fusion-secondary-title">
              <strong>Current version</strong> is processed, but a version sync error
              prevented identifying the latest version
            </div>
          </div>
        ),
        title: `Version ${fusionVersion}`,
      };
    }

    if (isCurrentVersionLatest) {
      return {
        icon: (
          <FontAwesomeIcon
            icon={faCheckCircle}
            color="#1ac98e"
            className="spacer-right"
          />
        ),
        content: (
          <div>
            <div className="fusion-main-title">Ready</div>
            <div className="fusion-secondary-title">
              <strong>Current version</strong> is processed and is the most
              recent version.
            </div>
          </div>
        ),
        title: `Version ${fusionVersion}`,
      };
    }
    return {
      icon: (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          color="#f0ad4e"
          className="spacer-right"
        />
      ),
      content: (
        <div>
          <div className="fusion-main-title">
            <FormattedMessage id="field.ready" defaultMessage="Ready" />
          </div>
          <div className="fusion-secondary-title">
            <FormattedMessage
              id="field.moreRecentAvailable"
              defaultMessage="More recent version(s) available, consider updating"
            />{' '}
            <strong>current version</strong>.
          </div>
          <Button
            onClick={handleShowVersionsModal}
            size="sm"
            variant="outline-light"
            // Hide the tooltip instantly when the button is clicked
            className="spacer-right fusion-action-btn action-tooltip-hide-on-click"
          >
            <FontAwesomeIcon
              icon={faArrowAltCircleUp}
              className="spacer-right"
            />
            <FormattedMessage id="button.update" defaultMessage="Update" />
          </Button>
        </div>
      ),
      title: `Version ${fusionVersion}`,
    };
  };

  // Status content configuration
  const statusContent = {
    [AUTODESK_FUSION_MODEL_STATUSES.ready]: renderVersionStatusContent(),
    [AUTODESK_FUSION_MODEL_STATUSES.processing]: {
      icon: <Loading className="spacer-right" inline />,
      content: <FormattedMessage id="field.pleaseWait" defaultMessage="Please wait" />,
      title: <FormattedMessage id="field.modelProcessing" defaultMessage="Model is processing" />,
    },
    [AUTODESK_FUSION_MODEL_STATUSES.created]: {
      icon: <Loading className="spacer-right" inline />,
      content: <FormattedMessage id="field.pleaseWait" defaultMessage="Please wait" />,
      title: <FormattedMessage id="field.modelCreating" defaultMessage="Model is being created" />,
    },
    [AUTODESK_FUSION_MODEL_STATUSES.error]: {
      icon: (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          color="#dc3545"
          className="spacer-right"
        />
      ),
      content: '',
      title: <FormattedMessage id="field.modelProcessingError" defaultMessage="An issue occurred during model processing" />,
    },
    modelNotUploaded: {
      icon: <FontAwesomeIcon icon={faInfoCircle} className="spacer-right" />,
      content: '',
      title: <FormattedMessage id="field.modelNotUploaded" defaultMessage="Model upload to Autodesk Fusion is pending" />,
    },
    notSignedIn: {
      icon: <FontAwesomeIcon icon={faInfoCircle} className="spacer-right" />,
      content: '',
      title: 'You are not signed in to Autodesk Fusion',
    },
    modelUploading: {
      icon: <Loading className="spacer-right" inline />,
      content: 'Model is currently being uploaded',
      title: 'Model is uploading',
    },
    versionSyncing: {
      icon: <Loading className="spacer-right" inline />,
      content: <FormattedMessage id="field.modelSyncing" defaultMessage="Syncing model version with Autodesk Fusion" />,
      title: <FormattedMessage id="field.versionSyncing" defaultMessage="Version syncing" />,
    },
    modelFetching: {
      icon: <Loading className="spacer-right" inline />,
      content: '',
      title: <FormattedMessage id="field.modelInitializing" defaultMessage="Initializing" />,
    },
  };

  // Link states configuration
  const fusionLinkStateByStatus = {
    [AUTODESK_FUSION_MODEL_STATUSES.processing]: processingContent,
    [AUTODESK_FUSION_MODEL_STATUSES.created]: processingContent,
    [AUTODESK_FUSION_MODEL_STATUSES.ready]: {
      href: fusionUri,
      newTab: true,
    },
    [AUTODESK_FUSION_MODEL_STATUSES.error]: {},
    versionSyncing: processingContent,
    modelNotUploaded: {},
    modelUploading: {},
    notSignedIn: {},
  };

  // Determine current status code
  const getCurrentStatus = () => {
    // User not signed in to Fusion
    if (!fusionToken) return 'notSignedIn';

    // No fusion model found
    if (!fusionModel) {
      const modelErrorStatuses = [
        MODEL_STATUSES.ERROR,
        MODEL_STATUSES.FILE_TOO_LARGE,
        MODEL_STATUSES.IRREPARABLE,
        MODEL_STATUSES.IRREPARABLE,
      ];
      // Show "Model is Uploading" if no snapshot and the status is appropriate
      if (!model?.snapshot
        && !modelErrorStatuses.includes(model?.status)) return 'modelUploading';
      return 'modelNotUploaded';
    }

    // Model is being fetched
    if (fusionModelFetching) return 'modelFetching';

    // Version syncing
    if (fusionModelVersionsFetching) return 'versionSyncing';

    // Return model actual status
    return fusionModel.status;
  };

  const currentStatusCode = getCurrentStatus();

  const currentStatus =
    statusContent[currentStatusCode] || statusContent.modelNotUploaded;

  const linkState = fusionLinkStateByStatus[currentStatusCode] || {};

  const renderStatusContent = () => (
    <div>
      <ActionTooltip
        id="fusionStatusTooltip"
        placement="top"
        trigger={currentStatus.icon}
        hideTooltipText={!currentStatus.content}
      >
        {currentStatus.content}
      </ActionTooltip>
      {currentStatus.title}
    </div>
  );

  const renderHistoryButton = () => {
    // Skip showing history button if the user is not signed in
    if (!fusionToken) return null;
    if (fusionVersionError) {
      return (
        <ActionTooltip
          id="fusionStatusVersionsTooltip"
          placement="top"
          trigger={(
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color="#dc3545"
              className="spacer-right"
            />
          )}
        >
          <div className="fusion-main-title">Something went wrong</div>
          <div className="fusion-secondary-title">
            Model versions could not be fetched.
            Please refresh the page to try again later.
          </div>
        </ActionTooltip>
      );
    }

    if (!fusionModelVersionsFetching && !fusionModelFetching) {
      return (
        !_isEmpty(fusionModelVersions) &&
         !_isEmpty(fusionModel) &&
         (
           <Button
             onClick={handleShowVersionsModal}
             size="sm"
             variant="outline-light"
           >
             <FontAwesomeIcon
               icon={faHistory}
               color="#19A8DD"
               className="fusion-versions-btn"
             />
           </Button>
         )

      );
    }

    return null;
  };

  const renderFusionButton = () => {
    // Skip showing Fusion button if the user is not signed in
    if (!fusionToken || fusionModel?.status === AUTODESK_FUSION_MODEL_STATUSES.error) return null;
    if ((!fusionModelVersionsFetching && !fusionModelFetching) && fusionModel) {
      return (
        <FusionButtonLink
          {...linkState}
          size="sm"
          content={
            <FontAwesomeIcon icon={faChevronRight} className="ml3" />
          }
        />
      );
    }
    return null;
  };

  return (
    <Card
      className={`m-b ${fusionModelFetching ? '' : 'fusion-card'}`}
      bg="light"
    >
      <Card.Header className="pd-exp">
        <div className="d-flex justify-content-between align-items-center">
          <FormattedMessage id="field.autodeskFusion" defaultMessage="Autodesk Fusion" />
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between">
          {renderStatusContent()}
          <div className="d-flex align-items-center">
            {renderHistoryButton()}
            {renderFusionButton()}
          </div>
        </div>
      </Card.Body>

      <FusionVersionsModal
        show={showVersionsModal}
        fusionModelVersions={fusionModelVersions}
        fetchFusionModelVersions={fetchFusionModelVersions}
        fusionModelVersionsFetching={fusionModelVersionsFetching}
        fusionModelVersionUpdating={fusionModelVersionUpdating}
        onClose={handleCloseVersionsModal}
        fusionModel={fusionModel}
        onSubmit={handleSetFusionVersion}
      />

    </Card>
  );
};

AutodeskFusionPanel.propTypes = {
  model: PropTypes.shape({
    uri: PropTypes.string,
    status: PropTypes.string,
    snapshot: PropTypes.string,
  }).isRequired,
  newModelLibraryCreation: PropTypes.bool.isRequired,
};

export default AutodeskFusionPanel;
