import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import FusionLogo from 'rapidfab/icons/fusion-logo';

const FusionButtonLink = ({ href, content, hidden, newTab, size = 'md' }) => {
  if (hidden) return null;

  const target = newTab ? '_blank' : undefined;
  const linkRelationship = newTab ? 'noopener noreferrer' : undefined;
  const linkClasses = classNames('fusion-connect-button', {
    'fusion-connect-link-disabled': !href,
    'fusion-connect-button-sm': size === 'sm',
  });

  const logoSizes = {
    sm: {
      width: 20,
      height: 18,
    },
    md: {
      width: 27,
      height: 25,
    },
  };

  return (
    <a
      href={href}
      className={linkClasses}
      target={target}
      rel={linkRelationship}
    >
      <FusionLogo width={logoSizes[size].width} height={logoSizes[size].height} />
      {content && <div className="fusion-connect-button-text">{content}</div>}
    </a>
  );
};

FusionButtonLink.defaultProps = {
  content: 'View in Fusion',
  hidden: false,
  newTab: true,
  size: 'md',
};

FusionButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  content: PropTypes.string,
  hidden: PropTypes.bool,
  newTab: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md']),
};

export default FusionButtonLink;
