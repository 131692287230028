import FormattedNumberByLocales from 'rapidfab/components/FormattedNumberByLocales';
import { getMaterialBatchActionQuantity } from 'rapidfab/utils/materialBatchAction';
import React from 'react';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { MATERIAL_BATCH_ACTION_TYPES, ROUTES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { Link } from 'react-router-dom';

const MaterialBatchMetadataColumn = ({ rowData, locations, subLocations, batchUnits }) => {
  const { action_type: actionType, metadata, notes } = rowData;
  let batchUri = '';

  if (metadata) {
    batchUri = metadata.resulting_batch || metadata.split_off_batch || null;
  }

  switch (actionType) {
    case (MATERIAL_BATCH_ACTION_TYPES.RELOCATE): {
      const location = _find(locations, { uri: rowData?.metadata.destination_location });
      const subLocation = _find(subLocations, { uri: rowData?.metadata.destination_sub_location });

      return (
        <>
          <p>
            Location:{' '}
            <i>
              <Link
                to={getRouteURI(ROUTES.LOCATIONS, null, { uuid: extractUuid(location?.uri) }, true)}
              >
                {location?.name} ({getShortUUID(location?.uri)})
              </Link>
            </i>
          </p>
          {' '}
          <p>
            Sub Location:{' '}
            <i>{subLocation?.name} ({getShortUUID(subLocation?.uri)})</i>
          </p>
        </>
      );
    }
    case (MATERIAL_BATCH_ACTION_TYPES.BLEND_BATCHES): {
      const sourceBatch = rowData?.source_batch;
      const sourceBatchPreviousQuantity = rowData.metadata?.previous_source_batch_quantity;
      const previousBatchToBlendQuantity = rowData.metadata?.previous_batch_to_blend_quantity;
      const resultingBatchQuantity = rowData?.quantity;
      const blendedBatchUri = metadata?.batch_to_blend;
      const resultingBatchUri = metadata?.resulting_batch;

      if (sourceBatchPreviousQuantity && previousBatchToBlendQuantity) {
        return (
          <div>
            A Source batch&nbsp;
            <div>
              <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(sourceBatch) })}>
                {getShortUUID(sourceBatch)}
              </a>
            </div> of <strong>{sourceBatchPreviousQuantity} {batchUnits}</strong> was blended with batch&nbsp;
            <div>
              <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(blendedBatchUri) })}>
                {getShortUUID(blendedBatchUri)}
              </a>
            </div> of <strong>{previousBatchToBlendQuantity} {batchUnits}</strong> resulting in a new batch&nbsp;
            <div>
              <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(resultingBatchUri) })}>
                {getShortUUID(resultingBatchUri)}
              </a>
            </div> containing
            <strong>
              <FormattedNumberByLocales
                number={getMaterialBatchActionQuantity({ quantity: resultingBatchQuantity })}
                shouldTruncateNumber
              /> {batchUnits}
            </strong>
          </div>
        );
      }

      return (
        <>
          <span>New Batch: </span>
          <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(batchUri) })}>
            {getShortUUID(batchUri)}
          </a>
        </>
      );
    }
    case (MATERIAL_BATCH_ACTION_TYPES.MACHINE_LOAD):
      if (!metadata?.printer) return notes;
      return (
        <>
          <span>Loaded to the machine: </span>
          <a href={getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(metadata.printer) })}>
            {getShortUUID(metadata.printer)}
          </a>
        </>
      );
    case (MATERIAL_BATCH_ACTION_TYPES.SPLIT_BATCH):
      if (!metadata?.split_off_batch) return notes;
      return (
        <>
          <span>New batch created: </span>
          <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(metadata.split_off_batch) })}>
            {getShortUUID(metadata.split_off_batch)}
          </a>
        </>
      );
    case (MATERIAL_BATCH_ACTION_TYPES.CONTAINERIZE_BATCH): {
      if (!metadata?.containers?.length) return notes;

      return (
        <>
          <p>All Containers: </p>

          {metadata.containers.map(container => (
            <div>
              <p className="mb0"><i>{getShortUUID(container.uri)}</i></p>
              <p><i>Quantity: {container.quantity} {batchUnits && `(${batchUnits})`}</i></p>
            </div>
          ))}
        </>
      );
    }
    case (MATERIAL_BATCH_ACTION_TYPES.MACHINE_TOP_OFF):
      if (!metadata?.batch_to_load && !metadata?.printer) return notes;
      return (
        <>
          <p className="mb0">Batch:&nbsp;
            <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(metadata.batch_to_load) })}>
              <p className="mb0">{getShortUUID(metadata.batch_to_load)}</p>
            </a>
            <p className="mb0">loaded (top off) to the machine:</p>
            <a href={getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(metadata.printer) })}>
              <p>{getShortUUID(metadata.printer)}</p>
            </a>
            {metadata.resulting_batch && (
              <>
                <p className="mb0">Resulting Batch:</p>
                <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(metadata.resulting_batch) })}>
                  <p className="mb0">{getShortUUID(metadata.resulting_batch)}</p>
                </a>
              </>
            )}
          </p>
        </>
      );
    case (MATERIAL_BATCH_ACTION_TYPES.DEFER_MODULAR_BUILD): {
      const buildBatch = rowData.metadata?.build_batch;
      const overflowBatch = rowData.metadata?.overflow_batch;
      const printer = rowData.metadata?.printer;
      const externalBuildId = rowData.metadata?.external_build_id;
      return (
        <>
          {buildBatch && (
            <p>Build Batch:&nbsp;
              <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(buildBatch) })}>
                <p className="mb0">{getShortUUID(buildBatch)}</p>
              </a>
            </p>
          )}
          {overflowBatch && (
            <p>Overflow Batch:&nbsp;
              <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(overflowBatch) })}>
                <p className="mb0">{getShortUUID(overflowBatch)}</p>
              </a>
            </p>
          )}
          {printer && (
            <p>Printer:&nbsp;
              <a href={getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(printer) })}>
                <p className="mb0">{getShortUUID(printer)}</p>
              </a>
            </p>
          )}
          {externalBuildId && (
            <p>External Build ID:&nbsp;
              <i>{externalBuildId}</i>
            </p>
          )}
        </>
      );
    }
    case (MATERIAL_BATCH_ACTION_TYPES.MACHINE_UNLOAD_CHAMBER):
      if (!metadata?.resulting_batch) return notes;

      return (
        <>
          <span>New batch created: </span>
          <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(metadata.resulting_batch) })}>
            {getShortUUID(metadata.resulting_batch)}
          </a>
        </>
      );
    case (MATERIAL_BATCH_ACTION_TYPES.MACHINE_UNLOAD_HOPPER):
      if (!metadata?.resulting_batch) return notes;

      return (
        <>
          <span>New batch created: </span>
          <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(metadata.resulting_batch) })}>
            {getShortUUID(metadata.resulting_batch)}
          </a>
        </>
      );
    case (MATERIAL_BATCH_ACTION_TYPES.CREATE_INITIAL_BATCH):
      if (!metadata?.material_lot) return notes;

      return (
        <>
          <span>Deliver Lot: </span>
          <a href={getRouteURI(ROUTES.MATERIAL_LOT, { uuid: extractUuid(metadata.material_lot) })}>
            {getShortUUID(metadata.material_lot)}
          </a>
        </>
      );
    case (MATERIAL_BATCH_ACTION_TYPES.SIEVE): {
      const remainingQuantity = rowData.metadata?.remaining_quantity;
      return (
        <div>
          <p className="mb5">
            Notes:
          </p>
          <i>
            Sieve the contents of this batch.
          </i>

          {remainingQuantity && batchUnits && (
            <p className="mt5">Remaining Quantity:{' '}
              <i>{remainingQuantity} {batchUnits}</i>
            </p>
          )}
        </div>
      );
    }
    default:
      return notes;
  }
};

export default MaterialBatchMetadataColumn;

MaterialBatchMetadataColumn.propTypes = {
  rowData: PropTypes.shape({
    notes: PropTypes.string,
    action_type: PropTypes.string,
    source_batch: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    metadata: PropTypes.shape({
      resulting_batch: PropTypes.shape({}),
      split_off_batch: PropTypes.shape({}),
      remaining_quantity: PropTypes.number,
      build_batch: PropTypes.string,
      overflow_batch: PropTypes.string,
      external_build_id: PropTypes.string,
      containers: PropTypes.arrayOf(PropTypes.shape({})),
      destination_location: PropTypes.string,
      destination_sub_location: PropTypes.string,
      printer: PropTypes.string,
      material_lot: PropTypes.string,
      previous_source_batch_quantity: PropTypes.number,
      previous_batch_to_blend_quantity: PropTypes.number,
      batch_to_blend: PropTypes.string,
      batch_to_load: PropTypes.string,
    }),
  }).isRequired,
  batchUnits: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
