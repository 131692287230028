import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  getUsersByUri,
  getBureau,
  getCurrentUserRole,
  isFeatureEnabled,
  getLibraries,
  getSession,
} from 'rapidfab/selectors';
import { USER_ROLES, FEATURES, API_RESOURCES } from 'rapidfab/constants';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import OwnerPanelHeader from 'rapidfab/components/records/model_library/owner/OwnerPanelHeader';
import EditOwner from 'rapidfab/components/records/model_library/owner/EditOwner';
import _values from 'lodash/values';
import { modelLibraryType, userResourceType, bureauResourceType } from 'rapidfab/types';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';

const OwnerPanel = ({
  usersByUri,
  modelLibrary,
  currentUserRole,
  currentUser,
  isHawkingUser,
  onUpdate,
  isStanleyXUser,
  bureau,
  isDigitalDesignWarehouseFeatureEnabled,
}) => {
  const isBureauOwner = getEndpointFromURI(modelLibrary.owner).endpointName === API_RESOURCES.BUREAU;
  const isManager = currentUserRole === USER_ROLES.MANAGER;
  const otherUserRoles = !isManager && Object.values(USER_ROLES).includes(currentUserRole);
  const isCompanyLibrary = modelLibrary.owner === bureau.uri;

  const libraries = useSelector(getLibraries);

  const ownerOptions = useMemo(() => {
    // Convert libraries object into an array of options
    const libraryList = Object.values(libraries || {});
    const users = usersByUri || {};

    // Determine user options based on manager status
    let userList;
    if (isManager) {
      // If the user is a manager, include all users as options
      userList = Object.values(users);
    } else {
      // If not a manager, only include the current user if they exist in the users list
      const currentUserData = users[currentUser.uri];
      userList = currentUserData ? [currentUserData] : [];
    }

    // Combine library and user options into a single array
    return [...libraryList, ...userList];
  }, [isManager, libraries, usersByUri, currentUser.uri]);

  const [isEditingMode, setIsEditingMode] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [newOwnerUri, setNewOwnerUri] = useState(modelLibrary.owner);

  const owner = useMemo(() => {
    const ownerId = extractUuid(newOwnerUri);

    return isBureauOwner ? bureau : ownerOptions.find(({ owner, uri }) =>
      (owner ? owner.includes(ownerId) : uri.includes(ownerId)),
    );
  }, [bureau, isBureauOwner, newOwnerUri, ownerOptions]);

  const onSave = async ownerUri => {
    if (ownerUri !== modelLibrary.owner) {
      setIsSaving(true);
      try {
        await onUpdate({ owner: ownerUri, uuid: modelLibrary.uuid });
      } finally {
        setIsEditingMode(false);
        setIsSaving(false);
      }
    } else {
      setIsEditingMode(false);
    }
  };

  const onPublish = () => onSave(newOwnerUri);
  const onSubmitForm = event => {
    event.preventDefault();

    onSave(newOwnerUri);
  };

  return (
    <form onSubmit={onSubmitForm}>
      <Card className="m-b" bg="light">
        <Card.Header className="pd-exp">
          <OwnerPanelHeader
            isManager={isManager}
            isSaving={isSaving}
            isHawkingUser={isHawkingUser}
            isEditingMode={isEditingMode}
            setIsEditingMode={setIsEditingMode}
            isStanleyXUser={isStanleyXUser}
            isDigitalDesignWarehouseFeatureEnabled={isDigitalDesignWarehouseFeatureEnabled}
            isCompanyLibrary={isCompanyLibrary}
            otherUserRoles={otherUserRoles}
          />
        </Card.Header>
        <Card.Body>
          {isEditingMode
            ? (
              <EditOwner
                bureau={bureau}
                users={_values(ownerOptions)}
                newOwnerUri={newOwnerUri}
                setNewOwnerUri={setNewOwnerUri}
                onPublish={onPublish}
                isManager={isManager}
              />
            ) : (
              <div>
                <b className="spacer-right">
                  <FormattedMessage id="field.owner" defaultMessage="Owner" />:
                </b>
                {owner ? owner.name : <FormattedMessage id="notAvailable" defaultMessage="N/A" />}
              </div>
            )}
        </Card.Body>
      </Card>
    </form>
  );
};
OwnerPanel.propTypes = {
  usersByUri: PropTypes.objectOf(userResourceType).isRequired,
  modelLibrary: modelLibraryType.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  isStanleyXUser: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
  bureau: bureauResourceType.isRequired,
  currentUser: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  usersByUri: getUsersByUri(state),
  bureau: getBureau(state),
  currentUserRole: getCurrentUserRole(state),
  currentUser: getSession(state),
  isHawkingUser: isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT) ||
    isFeatureEnabled(state, FEATURES.AUTHENTISE_PDM),
  isStanleyXUser: isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT),
});

export default connect(mapStateToProps)(OwnerPanel);
