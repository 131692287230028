import AssetTypesTabs from 'rapidfab/components/assets/AssetTypesTabs';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import AddFromDB from 'rapidfab/containers/inventory/AddFromDBContainer';
import DisabledIconWithTooltip from 'rapidfab/components/DisabledIconWithTooltip';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Table from 'rapidfab/components/Tables/Table';
import { useSelector } from 'react-redux';
import { getIsDebugModeEnabled } from 'rapidfab/selectors';
import { Link, useLocation } from 'react-router-dom';
import useDisabledFor from 'rapidfab/hooks/useDisabledFor';

const PrinterTypes = ({
  printerTypes,
  fetching,
  setCreatePrinterTypeModal,
  isTableDataFetching,
}) => {
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);
  const { isDisabled } = useDisabledFor({ nonManager: true });

  const location = useLocation();

  const breadcrumbs = useMemo(() => {
    const breadcrumbs = ['assets', 'assetTypes'];
    const isWorkstationsMainRoute = location.pathname === ROUTES.ASSET_TYPES;
    if (!isWorkstationsMainRoute) {
      breadcrumbs.push('printerTypes');
    }
    return breadcrumbs;
  }, [location.pathname]);

  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'printer-type',
      Cell: ({ row: { original: data } }) => (
        <Link
          to={getRouteURI(ROUTES.PRINTER_TYPES, null, { uuid: data.uuid }, true)}
        >
          {getShortUUID(data.uri)}
        </Link>
      ),
    },
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original } }) => (
        <div>
          {original.name}
          {!original.active_printers.length && (
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  <FormattedMessage
                    id="tooltip.PTHasNoActivePrinters"
                    defaultMessage="Printer Type has no active Printers"
                  />
                </Tooltip>
              )}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} className="spacer-left" color="#ffd500" />
            </OverlayTrigger>
          )}
        </div>
      ),
    },
  ];
  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />
      <Row>
        <Col xs={12} className="mb8">
          <AssetTypesTabs activeKey="printer-types" />
        </Col>
        <Col xs={12}>
          <div className="pull-right">
            {isDisabled && (
              isDebugModeEnabled ? (
                <DisabledIconWithTooltip
                  id="limitedFunctionalityDebugMode"
                  defaultMessage="This functionality usage is limited. Contact your Bureau Manager if you have any questions. Debug Info: Locked due to configuration of User Groups and Group Qualifications. See docs for more details."
                  placement="bottom"
                />
              )
                : <DisabledIconWithTooltip placement="bottom" />
            )}
            <AddFromDB
              disabled={isDisabled}
              type="printer-type"
              buttonText={(
                <UseNonMfgLanguageFeature
                  mfgLanguageComponent={(
                    <FormattedMessage
                      id="record.printerType.add"
                      defaultMessage="Add Printer Type"
                    />
                  )}
                  nonMfgLanguageComponent={(
                    <FormattedMessage
                      id="mfg.addPrinterType.addProductionDevice"
                      defaultMessage="Add Production Device"
                    />
                  )}
                />
              )}
              modalTitle={(
                <UseNonMfgLanguageFeature
                  mfgLanguageComponent={<FormattedMessage id="printerdb.search" defaultMessage="Printer Search" />}
                  nonMfgLanguageComponent={(
                    <FormattedMessage
                      id="mfg.printerSearch.productionDeviceSearch"
                      defaultMessage="Production Device Search"
                    />
                  )}
                />
              )}
              itemLabel={(
                <UseNonMfgLanguageFeature
                  mfgLanguageComponent={<FormattedMessage id="field.printerType" defaultMessage="Printer Type" />}
                  nonMfgLanguageComponent={(
                    <FormattedMessage
                      id="mfg.printerType.productionDeviceType"
                      defaultMessage="Production Device Type"
                    />
                  )}
                />
              )}
              manualFunction={() => setCreatePrinterTypeModal(true)}
              manualCreateHref={getRouteURI(ROUTES.PRINTER_TYPE_NEW)}
              manualCreateText={(
                <UseNonMfgLanguageFeature
                  mfgLanguageComponent={(
                    <FormattedMessage
                      id="printerdb.notlisted"
                      defaultMessage="My printer isn’t listed"
                    />
                  )}
                  nonMfgLanguageComponent={(
                    <FormattedMessage
                      id="mfg.printerNotListed.productionDeviceNotListed"
                      defaultMessage="My Production Device isn’t listed"
                    />
                  )}
                />
              )}
            />
          </div>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col xs={12}>
          {fetching ? (
            <Loading />
          ) : (
            <Table
              tableID="printerTypes"
              data={printerTypes}
              isFetching={isTableDataFetching}
              columns={columns}
              isFilteringEnabled
              withDefaultPagination
              isManualSoringEnabled={false}
              initialSortedColumn="name"
              initialSortedDesc={false}
              isUpdatedColumnShown={false}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

PrinterTypes.propTypes = {
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      active_printers: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  setCreatePrinterTypeModal: PropTypes.func.isRequired,
  isTableDataFetching: PropTypes.bool.isRequired,
};

export default PrinterTypes;
