import { faCheckCircle, faChevronRight, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import FusionButtonLink from 'rapidfab/components/autodesk-fusion/FusionButtonLink';
import { ActionTooltip } from 'rapidfab/components/Tooltip';
import {
  API_RESOURCES,
  AUTODESK_FUSION_MODEL_STATUSES,
  AUTODESK_FUSION_RUN_SETUP_STATUSES,
  RUN_OPERATIONS,
} from 'rapidfab/constants';
import { getFusionRunSetup } from 'rapidfab/selectors';
import { getStateFusionUserToken } from 'rapidfab/selectors/baseStateSelectors';
import React, { useEffect } from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'rapidfab/components/Loading';

const FusionRunPanel = ({ runURI, runOperation }) => {
  const fusionRunSetup = useSelector(state => getFusionRunSetup(state)[0]);
  const fusionToken = useSelector(state => getStateFusionUserToken(state)[0]);
  const fusionRunSetupFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.FUSION_ADDITIVE_SETUP].list.fetching);

  const dispatch = useDispatch();

  const onInitialize = () => {
    if (!runURI) return;

    dispatch(Actions.Api.nautilus[API_RESOURCES.FUSION_ADDITIVE_SETUP].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.FUSION_ADDITIVE_SETUP].list({
      run: runURI,
    }));
  };

  useEffect(() => {
    onInitialize();
  }, [runURI]);

  const fusionUri = fusionRunSetup?.fusion_uri;
  const fusionVersion = fusionRunSetup?.version;

  // Common processing content configuration
  const processingContent = {
    content: <span>Processing</span>,
    disabled: true,
    hidden: true,
  };

  const renderVersionStatusContent = () => ({
    icon: (
      <FontAwesomeIcon
        icon={faCheckCircle}
        color="#1ac98e"
        className="spacer-right"
      />
    ),
    content: (
      <div>
        <div className="fusion-main-title">Complete</div>
        <div className="fusion-secondary-title">
          Build is processed and ready to use
        </div>
        <div>
          <strong>Version {fusionVersion}</strong>
        </div>
      </div>
    ),
    title: 'Complete',
  });

  // Status content configuration
  const statusContent = {
    [AUTODESK_FUSION_RUN_SETUP_STATUSES.complete]: renderVersionStatusContent(),
    [AUTODESK_FUSION_RUN_SETUP_STATUSES.processing]: {
      icon: <Loading className="spacer-right" inline />,
      content: <FormattedMessage id="field.pleaseWait" defaultMessage="Please wait" />,
      title: <FormattedMessage id="field.buildProcessing" defaultMessage="Build is processing" />,
    },
    [AUTODESK_FUSION_RUN_SETUP_STATUSES.pending]: {
      icon: <Loading className="spacer-right" inline />,
      content: <FormattedMessage id="field.pleaseWait" defaultMessage="Please wait" />,
      title: <FormattedMessage id="field.buildCreating" defaultMessage="Build is being created" />,
    },
    [AUTODESK_FUSION_RUN_SETUP_STATUSES.error]: {
      icon: (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          color="#dc3545"
          className="spacer-right"
        />
      ),
      content: '',
      title: <FormattedMessage id="field.buildProcessingError" defaultMessage="An issue occurred during build processing" />,
    },
    modelNotUploaded: {
      icon: <FontAwesomeIcon icon={faInfoCircle} className="spacer-right" />,
      content: '',
      title: <FormattedMessage id="field.buildNotUploaded" defaultMessage="Build upload to Autodesk Fusion is pending" />,
    },
    notSignedIn: {
      icon: <FontAwesomeIcon icon={faInfoCircle} className="spacer-right" />,
      content: '',
      title: 'You are not signed in to Autodesk Fusion',
    },
  };

  // Link states configuration
  const fusionLinkStateByStatus = {
    [AUTODESK_FUSION_RUN_SETUP_STATUSES.processing]: processingContent,
    [AUTODESK_FUSION_RUN_SETUP_STATUSES.pending]: processingContent,
    [AUTODESK_FUSION_RUN_SETUP_STATUSES.complete]: {
      href: fusionUri,
      newTab: true,
    },
    [AUTODESK_FUSION_RUN_SETUP_STATUSES.error]: {},
    modelNotUploaded: {},
    notSignedIn: {},
  };

  // Determine current status code
  const getCurrentStatus = () => {
    // User not signed in to Fusion
    if (!fusionToken) return 'notSignedIn';
    // No fusion model found
    if (!fusionRunSetup) {
      return 'modelNotUploaded';
    }

    // Return model actual status
    return fusionRunSetup.status;
  };

  const currentStatusCode = getCurrentStatus();

  const currentStatus =
    statusContent[currentStatusCode] || statusContent.modelNotUploaded;

  const linkState = fusionLinkStateByStatus[currentStatusCode] || {};

  const renderStatusContent = () => (
    <div>
      <ActionTooltip
        id="fusionStatusTooltip"
        placement="top"
        trigger={currentStatus.icon}
        hideTooltipText={!currentStatus.content}
      >
        {currentStatus.content}
      </ActionTooltip>
      {currentStatus.title}
    </div>
  );

  const renderFusionButton = () => {
    if (!fusionToken
      || fusionRunSetup?.status === AUTODESK_FUSION_MODEL_STATUSES.error) return null;
    if (!fusionRunSetupFetching && fusionRunSetup) {
      return (
        <FusionButtonLink
          {...linkState}
          size="sm"
          content={
            <FontAwesomeIcon icon={faChevronRight} className="ml3" />
          }
        />
      );
    }
    return null;
  };

  if (runOperation !== RUN_OPERATIONS.PRINTING) return null;

  return (
    <Row className="mb15">
      <Col xs={{ span: 6, offset: 3 }}>
        <Card className="custom-darken-modal--card mb15 fusion-card">
          <Card.Header className="pd-exp custom-darken-modal--card-header">
            <div className="d-flex justify-content-between align-items-center">
              <FormattedMessage id="field.autodeskFusion" defaultMessage="Autodesk Fusion" />
            </div>
          </Card.Header>
          <Card.Body className="custom-darken-modal--card-body">
            <div className="d-flex align-items-center justify-content-between">
              {renderStatusContent()}
              <div className="d-flex align-items-center">
                {renderFusionButton()}
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

FusionRunPanel.propTypes = {
  runURI: PropTypes.shape({}).isRequired,
  runOperation: PropTypes.string.isRequired,
};

export default FusionRunPanel;
