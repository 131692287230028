import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS } from 'rapidfab/constants';
import { faCommenting } from '@fortawesome/free-regular-svg-icons';

const ChipNote = () => (
  <div className="chip-note" style={{ backgroundColor: COLORS.BLUE }}>
    <p className="text-white mb-0">
      <FontAwesomeIcon icon={faCommenting} className="chip-note-icon" /> Review
    </p>
  </div>
);

export default ChipNote;
