import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Tooltip from 'rapidfab/components/Tooltip';
import { FormattedMessage } from 'react-intl';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const DisabledIconWithTooltip = ({ id, defaultMessage, values, placement, ...otherProps }) => (
  <FormattedMessage
    id={id}
    defaultMessage={defaultMessage}
    values={values}
  >
    {text => (
      <Tooltip
        // Re-using translation id for tooltip id attribute
        id={id}
        placement={placement}
        // Passing all extra props to the trigger icon to be able to customize it (e.g. add `pull-right` class)
        trigger={(<FontAwesomeIcon icon={faLock} className="spacer-left spacer-right" {...otherProps} />)}
      >
        {text}
      </Tooltip>
    )}
  </FormattedMessage>
);

DisabledIconWithTooltip.propTypes = {
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  values: PropTypes.shape({}),
  placement: PropTypes.string,
};

DisabledIconWithTooltip.defaultProps = {
  id: 'limitedFunctionality',
  defaultMessage: 'You do not have the permissions to complete this action. Please speak to your Manager / Super User to add them to your Bureau.',
  values: {},
  // Setting to undefined, in order to use default value from Tooltip when no custom value is provided
  placement: undefined,
};

export default DisabledIconWithTooltip;
