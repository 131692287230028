import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faPencil,
  faHistory,
  faCube,
  faSync,
  faChevronRight,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import FusionButtonLink from 'rapidfab/components/autodesk-fusion/FusionButtonLink';
import { FormattedDateTime } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';

const FusionVersionsModal = ({
  show,
  onClose,
  onSubmit,
  fusionModelVersions,
  fetchFusionModelVersions,
  fusionModel,
  fusionModelVersionsFetching,
  fusionModelVersionUpdating,
}) => {
  const [selectedVersion, setSelectedVersion] = useState(null);

  const handleToggleVersion = (version, event) => {
    // Prevent row click from interfering
    event.stopPropagation();
    setSelectedVersion(previousVersion =>
      (previousVersion === version ? null : version),
    );
  };

  const handleSaveVersion = () => {
    if (selectedVersion) {
      onSubmit(selectedVersion);
      setSelectedVersion(null);
    }
  };

  const isSaveButtonDisabled = !selectedVersion || fusionModelVersionsFetching || fusionModelVersionUpdating;
  const versionsFetching = fusionModelVersionsFetching || fusionModelVersionUpdating;

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onClose}
      backdrop="static"
      backdropClassName="priorityBackdrop"
      dialogClassName="line-item-quote-modal"
    >
      <Modal.Header>
        <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faHistory} />
            <p className="custom-darken-modal-title-text mt0">
              <FormattedMessage id="field.versionHistory" defaultMessage="Version History" />
            </p>
          </div>
          <FontAwesomeIcon
            icon={faClose}
            onClick={onClose}
            tabIndex={0}
            role="button"
            className="custom-darken-modal-button"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup fill className="mb15">
          <ListGroupItem className="resource-list-item-card-header">
            <Row>
              <Col xs={3}>
                <FormattedMessage id="field.version" defaultMessage="Version" />
              </Col>
              <Col xs={2}>
                <FormattedMessage id="field.lastModified" defaultMessage="Last modified" />
              </Col>
              <Col xs={2}>
                <FormattedMessage id="field.user" defaultMessage="User" />
              </Col>
              <Col xs={3}>
                <FormattedMessage id="email" defaultMessage="Email" />
              </Col>
              <Col xs={2} />
            </Row>
          </ListGroupItem>

          {versionsFetching ? (
            <Loading className="mt30" />
          ) : (
            fusionModelVersions?.map(fusionVersionItem => {
              const isCurrent = fusionModel?.version === fusionVersionItem.version;
              const isSelected = selectedVersion === fusionVersionItem.version;
              const shouldToggleCheckboxOnClick = !isCurrent && (!selectedVersion || isSelected);

              return (
                <ListGroupItem
                  key={fusionVersionItem.version}
                  className={`version-item resource-list-item-card-item ${
                    isSelected ? 'selected' : ''
                  }`}
                  onClick={event =>
                    shouldToggleCheckboxOnClick && handleToggleVersion(fusionVersionItem.version, event)}
                  role="button"
                  tabIndex={0}
                >
                  <Row className="align-items-center">
                    <Col xs={3} className="d-flex align-items-center">
                      <div className="fusion-version-icon-container spacer-right">
                        <FontAwesomeIcon
                          icon={faCube}
                          className="fusion-version-icon"
                        />
                      </div>
                      <span>
                        Version {fusionVersionItem.version} {isCurrent && (<span className="font-italic">(current)</span>)}
                      </span>
                    </Col>
                    <Col xs={2}>
                      <FormattedDateTime value={fusionVersionItem.updated} />
                    </Col>
                    <Col xs={2}>
                      {fusionVersionItem.modifier.first_name} {fusionVersionItem.modifier.last_name}
                    </Col>
                    <Col xs={3} className="wrap-text">{fusionVersionItem.modifier.email}</Col>
                    <Col xs={2} className="text-right">
                      <div className="d-flex justify-content-end align-items-center">
                        {!isCurrent && (!selectedVersion || isSelected) && (
                          <input
                            className="fusion-version-checkbox circled-checkbox mr10"
                            checked={isSelected}
                            onChange={event =>
                              handleToggleVersion(fusionVersionItem.version, event)}
                            onClick={event => event.stopPropagation()}
                            type="checkbox"
                          />
                        )}
                        <FusionButtonLink
                          href={fusionVersionItem.fusion_uri}
                          newTab
                          size="sm"
                          content={(
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              className="ml3"
                            />
                          )}
                        />
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })
          )}
        </ListGroup>

        {selectedVersion && (
          <div className="d-flex align-items-center justify-content-start">
            <FontAwesomeIcon icon={faInfoCircle} className="spacer-right" />
            <span className="font-weight-200">Please note, this will set</span>&nbsp;
            <div className="fusion-version-icon-container mr3">
              <FontAwesomeIcon
                icon={faCube}
                className="fusion-version-icon"
              />
            </div>
            <strong>Version {selectedVersion}</strong>
            &nbsp; <span className="font-weight-200">as current version.</span>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-end">
          <Button
            size="sm"
            variant="outline-light"
            className="spacer-right"
            disabled={versionsFetching}
            onClick={() => fetchFusionModelVersions(fusionModel.uri)}
          >
            {fusionModelVersionsFetching ?
              <Loading className="spacer-right" inline />
              : <FontAwesomeIcon icon={faSync} className="spacer-right" />}
            <FormattedMessage id="button.sync" defaultMessage="Sync" />
          </Button>
          <Button
            size="sm"
            variant="outline-light"
            onClick={handleSaveVersion}
            disabled={isSaveButtonDisabled}
          >
            {fusionModelVersionUpdating ?
              <Loading className="spacer-right" inline />
              : <FontAwesomeIcon icon={faPencil} className="spacer-right" />}
            <FormattedMessage id="button.save" defaultMessage="Save" />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

FusionVersionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fusionModelVersions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.number.isRequired,
      updated: PropTypes.string.isRequired,
      fusion_uri: PropTypes.string.isRequired,
      modifier: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  fusionModel: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
  }).isRequired,
  fetchFusionModelVersions: PropTypes.func.isRequired,
  fusionModelVersionsFetching: PropTypes.bool.isRequired,
  fusionModelVersionUpdating: PropTypes.bool.isRequired,
};

export default FusionVersionsModal;
