import { ActionTooltip } from 'rapidfab/components/Tooltip';
import FusionButton from 'rapidfab/components/autodesk-fusion/FusionButton';
import Loading from 'rapidfab/components/Loading';
import Config from 'rapidfab/config';
import { API_RESOURCES } from 'rapidfab/constants';
import FusionFail from 'rapidfab/icons/fusion-fail';
import FusionSuccess from 'rapidfab/icons/fusion-success';
import FusionWarning from 'rapidfab/icons/fusion-warning';
import Actions from 'rapidfab/actions';
import { getStateFusionUserToken } from 'rapidfab/selectors/baseStateSelectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const FusionStatusIndicator = () => {
  const fusionToken = useSelector(state => getStateFusionUserToken(state)[0]);
  const fusionTokenFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.OAUTH_TOKEN_AUTODESK_FUSION_USER].get.fetching);

  const redirect = encodeURIComponent(window.location.href);
  // https://data.<env>.com/<autodesk_fusion_user_auth>/?redirect=<current_url>
  const fusionRedirectUrl = `${Config.HOST.NAUTILUS}/${API_RESOURCES.OAUTH_TOKEN_AUTODESK_FUSION_USER_AUTH}/?redirect=${redirect}`;

  const dispatch = useDispatch();

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.OAUTH_TOKEN_AUTODESK_FUSION_USER].get(''));
  };

  useEffect(() => onInitialize(), []);

  const getStatus = () => {
    if (fusionTokenFetching) return 'loading';
    // Warning state is not available for now, will be tweaked later
    return fusionToken ? 'success' : 'fail';
  };

  const handleRedirectToFusionAuth = () => {
    window.location = fusionRedirectUrl;
  };

  const currentState = getStatus();

  const statusMap = {
    loading: {
      component: <Loading inline />,
      tooltip: 'Connecting to Autodesk Fusion...',
    },
    success: {
      component: <FusionSuccess />,
      tooltip: (
        <div>
          <div className="fusion-main-title">Connected</div>
          <div className="fusion-secondary-title">Fusion is successfully connected and ready for use.</div>
        </div>
      ),
    },
    fail: {
      component: <FusionFail />,
      tooltip: (
        <div>
          <div className="fusion-main-title">Fusion is not connected</div>
          <div className="fusion-secondary-title">Please sign in below to start working with Fusion.</div>
          <FusionButton onClick={handleRedirectToFusionAuth} content="Sign In" />
        </div>
      ),
    },
    warning: {
      component: <FusionWarning />,
      tooltip: (
        <div>
          <div className="fusion-main-title">Connection is lost</div>
          <div className="fusion-secondary-title">Please contact your bureau manager to set up Fusion.</div>
        </div>
      ),
    },
  };

  return (
    <li role="presentation" className="mr15 ml15">
      <ActionTooltip
        id="fusionStatusTooltip"
        placement="bottom"
        trigger={statusMap[currentState].component}
        modifiers={[
          {
            name: 'offset',
            options: {
              // Shift the tooltip slightly to the bottom
              offset: [0, 5],
            },
          },
        ]}
      >
        {statusMap[currentState].tooltip}
      </ActionTooltip>
    </li>
  );
};

export default FusionStatusIndicator;
